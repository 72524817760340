<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"> -->

    <!-- Bookmarks Container -->
    <!-- <bookmarks />
    </div> -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale />
      <dark-Toggler class="d-none d-lg-block" />
      <search-bar />
      <cart-dropdown /> -->
      <b-nav-item
        to="student-lesson-calendar"
      >
        <feather-icon
          icon="CalendarIcon"
          size="21"
        />
      </b-nav-item>
      <favorite-dropdown />
      <notification-dropdown />
      <!-- <user-dropdown v-if="flag === 1" /> -->
      <user-dropdown-company v-if="flag === 2" />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItem,
  // BLink,
} from 'bootstrap-vue'
// import Bookmarks from './components/Bookmarks.vue'
// import Locale from './components/Locale.vue'
// import SearchBar from './components/SearchBar.vue'
// import DarkToggler from './components/DarkToggler.vue'
// import CartDropdown from './components/CartDropdown.vue'
import FavoriteDropdown from '@/@core/layouts/components/app-navbar/components/FavoriteDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
// import UserDropdown from './components/UserDropdown.vue'
import UserDropdownCompany from './components/UserDropdownCompany.vue'

export default {
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    BNavItem,
    // Bookmarks,
    // Locale,
    // SearchBar,
    // DarkToggler,
    // CartDropdown,
    NotificationDropdown,
    // UserDropdown,
    FavoriteDropdown,
    UserDropdownCompany,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      flag: 2,
    }
  },
}
</script>
