<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          <!-- MyNickName<br> -->
          <!-- {{ userNickName }}<br> -->
          <small v-if="userDic.userNickName">
            {{ userDic.userNickName }}
          </small><br>
          <b v-if="userDic.coin">
            {{ userDic.coin.toLocaleString() }}
          </b>
          <!-- <small><b>{{ coin.toLocaleString() }}</b> coin</small> -->
          <!-- <small>{{ userType }}</small> -->
          <!-- {{ userData.fullName || userData.username }} -->
        </p>
        <!-- <span class="user-status">{{ userData.role }}</span> -->
      </div>
      <b-avatar
        size="40"
        :src="userDic.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>
    <small class="ml-1">アカウント</small>
    <b-dropdown-item>
      <feather-icon
        size="16"
        icon="UserCheckIcon"
        class="mr-50 text-primary"
      />
      <small
        class="text-primary"
      >
        求人企業ID
      </small>
      <br>
    </b-dropdown-item>
    <div class="mailSpace">
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      メールアドレス<br>
      <span class="ml-2">
        <small>{{ mail }}</small>
      </span>
    </div>
    <div class="dropdown_">
      <b-dropdown-divider />
      <small class="ml-1">求人企業</small>
      <b-dropdown-item
        :to="{ name: 'company-basicsetting-edit'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="FileMinusIcon"
          class="mr-50"
        />
        <span>基本情報</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'company-detailsetting-edit'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="FileTextIcon"
          class="mr-50"
        />
        <span>詳細情報</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'job-jobapplicant-list'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UserPlusIcon"
          class="mr-50"
        />
        <span>求人情報</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'job-contest-list'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="AwardIcon"
          class="mr-50"
        />
        <span>コンテスト</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'job-jobselection-edit'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="Edit3Icon"
          class="mr-50"
        />
        <span>選考方法</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'job-applylist'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="ClockIcon"
          class="mr-50"
        />
        <span>応募履歴</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'job-search-list'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="SearchIcon"
          class="mr-50"
        />
        <span>求職者検索</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'job-scout'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="DatabaseIcon"
          class="mr-50"
        />
        <span>スカウト管理</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'message' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="MailIcon"
          class="mr-50"
        />
        <span>メッセージ</span>
      </b-dropdown-item>
      <!-- <b-dropdown-item
        :to="{ name: 'apps-todo' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="CheckSquareIcon"
          class="mr-50"
        />
        <span>Task</span>
      </b-dropdown-item> -->
      <!-- <b-dropdown-divider /> -->
      <!-- <small class="ml-1">共通</small> -->
      <!-- <b-dropdown-item
        :to="{ name: 'user-chat' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="MessageSquareIcon"
          class="mr-50"
        />
        <span>チャット</span>
      </b-dropdown-item> -->
      <!-- <b-dropdown-item>
        <div class="coinSpace">
          <div>
            <a
              @click="move('student-pay-list')"
            >
              <feather-icon
                size="16"
                icon="GiftIcon"
                class="mr-50"
              />
              {{ Number(coin).toLocaleString() }} coin
            </a>
          </div>
          <div>
            <b-button
              variant="outline-primary"
              tag="a"
              size="sm"
              class="btn-wishlist"
            >
              <span>購入</span>
            </b-button>
          </div>
        </div>
      </b-dropdown-item> -->
      <b-dropdown-divider />

      <!-- <b-dropdown-item
        :to="{ name: 'pages-account-setting' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="SettingsIcon"
          class="mr-50"
        />
        <span>Settings</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'pages-pricing' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="CreditCardIcon"
          class="mr-50"
        />
        <span>Pricing</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'pages-faq' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="HelpCircleIcon"
          class="mr-50"
        />
        <span>FAQ</span>
      </b-dropdown-item> -->
      <b-dropdown-item
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="HelpCircleIcon"
          class="mr-50"
        />
        <span>ヘルプ</span>
      </b-dropdown-item>
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="XCircleIcon"
          class="mr-50"
        />
        <span>ログアウト</span></b-dropdown-item>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import { mapGetters } from 'vuex'
import store from '@/store'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // BBadge,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  computed: {
    ...mapGetters('userBasic', ['userDic', 'userType', 'userNickName', 'mail', 'coin']),
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      store.dispatch('userBasic/updateIsLogin', false)
      store.dispatch('userBasic/resetAll')

      // Redirect to login page
      this.$router.push({ name: 'login' })
      // this.$router.push({ name: 'auth-login' })
    },
    move(val) {
      this.$router.push({ name: val })
    },
  },
}
</script>

<style scoped>
.outline_ {
  outline: 1px solid rgb(134, 134, 134);
  /* padding: 1%; */
  border-radius: 2px;
  padding: 0 5%;
  /* font-size: 0.8rem; */
}
.mailSpace {
  margin: 3% 7% 5% 7%;
}
.coinSpace {
  display: flex;
  justify-content: space-between;
}
.dropdown_{
  /* 画面の高さが700px以上の場合は高さを700pxに固定する */
  /* max-height: 100px;
  overflow-y: auto; */
  height: 700px;
  overflow: auto; /* 高さを固定しないとスクロールしない */
}
@media screen and (max-height:700px){
  /* 画面の高さが700px以下の場合 */
  .dropdown_{
    height: 400px;
    overflow: auto;
  }
}
</style>
